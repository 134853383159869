<template>
  <div class="h-screen flex w-full bg-img">
    <div
      class="vx-col w-4/5 sm:w-4/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center"
    >
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div
            class="vx-row"
            style="background-color: #e30613; border-radius: 0.5%"
          >
            <div
              class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center"
            >
              <img
                src="@/assets/images/logo/caroo_logo_white.png"
                width="250px"
                alt="register"
                class="mx-auto"
              />
              <!-- <img src="" width="150px" alt="register" class="mx-auto"> -->
            </div>
            <div
              class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg"
            >
              <div class="p-8">
                <div class="vx-card__title mb-8">
                  <h4 class="mb-4">Recover your password</h4>
                  <p>
                    Please enter your email address and we'll send you
                    instructions on how to reset your password.
                  </p>
                </div>
                <vs-alert
                  :progress="progress"
                  :color="showDismissibleAlertColor"
                  :active.sync="showDismissibleAlert"
                  closable
                  icon-pack="feather"
                  close-icon="icon-x"
                  >{{ message }}</vs-alert
                >
                <label for="email">Email</label>
                <vs-input
                  v-validate="'required|email|min:3'"
                  data-vv-validate-on="blur"
                  name="email"
                  icon-no-border
                  icon="icon icon-user"
                  icon-pack="feather"
                  v-model="user.email"
                  class="w-full"
                />
                <span class="text-danger text-sm">{{
                  errors.first("email")
                }}</span>

                <br />
                <br />
                <vs-button
                  type="border"
                  to="/login"
                  class="px-4 w-full md:w-auto"
                  >Back To Login</vs-button
                >
                <vs-button
                  @click="recoverPassword"
                  class="float-right px-4 w-full md:w-auto mt-3 mb-8 md:mt-0 md:mb-0"
                  >Recover Password</vs-button
                >
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      user: {
        email: "",
      },
      showDismissibleAlert: false,
      showDismissibleAlertColor: "danger",
      active: false,
      time: 3000,
      progress: 0,
      message: "",
      loginError: "",
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.email != "";
    },
  },
  methods: {
    ...mapActions("users", ["forgotPassword"]),
    recoverPassword() {
      const payload = {
        userDetail: this.user,
      };
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$store
            .dispatch("auth/forgotPwd", payload)
            .then((response) => {
              this.showDismissibleAlert = true;
              this.message = response.data.message;
              this.active = true;
              this.showDismissibleAlertColor = "success";
              this.$vs.loading.close();
              this.$vs.notify({
                title: "Message",
                text: response.data.message,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "green",
              });
            })
            .catch((error) => {
              this.showErrorDismissibleAlert = true;
              this.message = error.message;
              this.active = true;
              this.showDismissibleAlertColor = "danger";
              this.$vs.loading.close();
              this.$vs.notify({
                title: "Error",
                text: error.message,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "danger",
              });
            });
        }
      });
    },
  },
  watch: {
    active(val) {
      if (val) {
        var interval = setInterval(() => {
          this.progress++;
        }, this.time / 100);

        setTimeout(() => {
          this.active = false;
          clearInterval(interval);
          this.progress = 0;
        }, this.time);
      }
    },
  },
};
</script>
